<!--
  - Copyright (C) 2024. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div>
    <!-- BEGIN: sidebar for add/edit tracks -->
    <sidebar
      class="sidebar"
      ref="trackSidebar"
      v-on:closeEvent="onCloseTrackSidebar()"
      :title="$t('ems.timeline.details.trackSettings')"
      :subtitle="$t('ems.timeline.details.chooseClient')"
    >
      <template v-slot:sidebar-content>
        <div class="mb-4" v-if="!trackData.edit_mode">
          <label label-for="timeline-driver"
            >{{ $t('ems.timeline.details.client') }}:</label
          >
          <b-form-select
            id="timeline-driver"
            v-model="trackData.clientUuid"
            :options="drivers_list"
            :state="trackData.clientUuid !== null"
            @change="trackData.shortcut_list = []"
            aria-describedby="timeline-driver-help"
          >
            <!-- This slot appears above the options from 'options' prop -->
            <template v-slot:first>
              <option :value="null" disabled>
                {{ $t('ems.timeline.details.clientPlaceholder') }}
              </option>
            </template>
          </b-form-select>
          <b-form-text id="timeline-driver-help">
            {{ $t('ems.timeline.details.clientHint') }}
          </b-form-text>
        </div>
        <div class="mb-4">
          <label label-for="timeline-name"
            >{{ $t('ems.timeline.details.name') }}:</label
          >
          <b-input
            id="timeline-name"
            aria-describedby="input-formatter-help"
            v-model="trackData.name"
            :placeholder="$t('ems.timeline.details.namePlaceholder')"
            :state="trackData.name !== null && trackData.name !== ''"
          >
          </b-input>
          <b-form-text id="timeline-name-help">
            {{ $t('ems.timeline.details.nameHint') }}
          </b-form-text>
        </div>
        <div class="mb-5">
          <b-form-group
            :label="$t('ems.timeline.details.shortcuts') + ':'"
            v-if="trackData.clientUuid"
          >
            <b-alert :show="true">
              <b-icon-info-circle></b-icon-info-circle>
              {{ $t('ems.timeline.details.shortcutHint') }}<br />
              <b>{{ $t('ems.timeline.details.shortcutNode') }}</b>
            </b-alert>
            <b-form-checkbox-group
              v-model="trackData.shortcut_list"
              :options="shortcut_list"
            >
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <div>
          <b-button
            block
            variant="success"
            @click="onSaveSettings()"
            :disabled="!track_data_set"
          >
            {{ $t('ems.timeline.details.save') }}
          </b-button>
        </div>
      </template>
    </sidebar>
    <!-- BEGIN: sidebar for add/edit events -->
    <sidebar
      class="sidebar"
      ref="eventWizard"
      :title="prototypeData.ui_title"
      :subtitle="prototypeData.ui_desc"
      v-on:closeEvent="resetPrototypeData()"
    >
      <template v-slot:sidebar-content v-if="prototypeData.driverData">
        <b-container>
          <b-alert
            variant="danger"
            :show="ui_edit_mode && current_prototype.timeError"
          >
            {{ $t('ems.timeline.events.conflict') }}
          </b-alert>
          <b-alert
            variant="warning"
            :show="ui_edit_mode && current_prototype.durationFixed"
          >
            {{ $t('ems.timeline.events.fixedDurationWarning') }}
          </b-alert>
          <b-row class="my-3" v-if="!ui_edit_mode">
            <b-col>
              <label>{{ $t('ems.timeline.events.method') }}:*</label>
              <b-form-select
                :options="methods_list.options"
                :value="prototypeData.selected"
                v-model="prototypeData.method"
                :disabled="input_disabled"
                @change="setPrototypeMethod"
              >
                <template v-slot:first>
                  <option :value="null" disabled>
                    {{ $t('ems.timeline.events.selectEvent') }}
                  </option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="prototypeData.prototypes.length" class="my-3">
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.eventDate') }}:*</label>
              <b-form-datepicker
                :disabled="input_disabled"
                v-model="current_prototype.date"
              >
              </b-form-datepicker>
            </b-col>
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.eventTime') }}:*</label>
              <b-input-group>
                <b-form-input
                  id="event-time-input"
                  v-model="current_prototype.time"
                  type="text"
                  :state="timeRegex.test(current_prototype.time) ? null : false"
                  :disabled="input_disabled"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    id="event-time"
                    show-seconds
                    :hour12="false"
                    button-only
                    right
                    aria-controls="event-time-input"
                    :disabled="input_disabled"
                    :placeholder="current_prototype.time"
                    v-model="current_prototype.time"
                  >
                  </b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="prototypeData.prototypes.length" class="my-3">
            <b-col md="12">
              <label>{{ $t('ems.timeline.events.frequency') }}:</label>
              <b-form-select
                ref="event_rrules_freq"
                :disabled="input_disabled"
                v-model="rruleSetOptions.selected"
                :options="rruleSetOptions.options"
                @change="setPrototypeRuleSet"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="rruleSetOptions.selected !== null" class="my-3">
            <b-col>
              <label>{{ $t('ems.timeline.events.daysOfWeek') }}:</label>
              <b-button-group class="d-flex">
                <b-button
                  v-for="(btn, idx) in rruleSetOptions.byweekday"
                  :key="'week' + idx"
                  @click="setPrototypeRuleSet"
                  :disabled="input_disabled"
                  :pressed.sync="btn.state"
                >
                  {{ btn.caption }}
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row v-if="rruleSetOptions.selected !== null" class="my-3">
            <b-col>
              <label>{{ $t('ems.timeline.events.month') }}:</label>
              <b-button-group class="d-flex">
                <b-button
                  v-for="(btn, idx) in rruleSetOptions.bymonth"
                  :key="'month' + idx"
                  @click="setPrototypeRuleSet"
                  :disabled="input_disabled"
                  :pressed.sync="btn.state"
                >
                  {{ btn.caption }}
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row v-if="rruleSetOptions.selected !== null" class="my-3">
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.interval') }}:</label>
              <b-form-input
                type="number"
                pattern="^[1-9]"
                placeholder="1"
                :disabled="input_disabled"
                v-model="rruleSetOptions.interval"
                @input="setPrototypeRuleSet"
              ></b-form-input>
              <small>{{ $t('ems.timeline.events.intervalHint') }}</small>
            </b-col>
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.count') }}:</label>
              <b-form-input
                type="number"
                pattern="^[0-9]"
                :placeholder="$t('ems.timeline.events.countPlaceholder')"
                :disabled="input_disabled"
                v-model="rruleSetOptions.count"
                min="0"
                :formatter="
                  (value) => {
                    return value > 0 ? value : null
                  }
                "
                @input="setPrototypeRuleSet"
              ></b-form-input>
              <small>{{ $t('ems.timeline.events.countHint') }}</small>
            </b-col>
          </b-row>
          <b-row v-if="rruleSetOptions.selected !== null" class="my-3">
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.untilDate') }}:</label>
              <b-form-datepicker
                v-model="rruleSetOptions.untilDate"
                reset-button
                @input="setPrototypeRuleSet"
              ></b-form-datepicker>
            </b-col>
            <b-col md="6">
              <label>{{ $t('ems.timeline.events.untilTime') }}:</label>
              <b-form-timepicker
                v-model="rruleSetOptions.untilTime"
                @input="setPrototypeRuleSet"
                show-seconds
                :hour12="false"
              ></b-form-timepicker>
            </b-col>
          </b-row>
          <b-row class="my-3" v-if="set_duration_enabled">
            <b-col>
              <label class="text-capitalize">
                {{ $t('ems.timeline.events.duration') }}:
              </label>
              <b-input-group>
                <b-form-input
                  id="event-duration-input"
                  v-model="current_prototype.durationHHMMSS"
                  type="text"
                  :state="
                    timeRegex.test(current_prototype.durationHHMMSS)
                      ? null
                      : false
                  "
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    show-seconds
                    :hour12="false"
                    v-model="current_prototype.durationHHMMSS"
                    button-only
                    right
                    aria-controls="event-duration-input"
                    :disabled="
                      current_prototype.durationFixed ||
                      current_prototype.timeError ||
                      !current_prototype.argsSet
                    "
                  ></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
              <b-form-text v-if="!current_prototype.argsSet">
                {{ $t('ems.timeline.events.argumentHint') }}
              </b-form-text>
            </b-col>
          </b-row>
          <b-row v-if="prototypeData.prototypes.length" class="my-3">
            <b-col>
              <!-- execute parameters setter -->
              <div
                v-for="key in current_prototype.argsKeys"
                :key="'args' + key"
              >
                <label :for="'event-param-' + key" class="text-capitalize">
                  {{ $t('ems.timeline.events.value', { key: key }) }}: *
                </label>
                <b-select
                  v-if="
                    getEventValues(prototypeData.driverData, current_prototype)
                  "
                  :value="null"
                  v-model="current_prototype.args[key]"
                  :options="
                    getEventValues(prototypeData.driverData, current_prototype)
                  "
                  :disabled="isSetInFirstPrototype(key)"
                >
                  <template v-slot:first>
                    <b-select-option :value="null" disabled>
                      {{ $t('ems.timeline.events.optionHint') }}
                    </b-select-option>
                  </template>
                </b-select>
                <b-input
                  v-else
                  type="text"
                  :id="'event-param-' + key"
                  :placeholder="$t('ems.timeline.events.parameterHint')"
                  :disabled="isSetInFirstPrototype(key)"
                  v-model="current_prototype.args[key]"
                ></b-input>
                <driver-action-ui
                  class="mb-4"
                  v-if="!isSetInFirstPrototype(key)"
                  v-bind:driver="
                    getDriverByClient(
                      getClient(prototypeData.driverData.clientId)
                    )
                  "
                  v-bind:client="getClient(prototypeData.driverData.clientId)"
                  v-bind:parameter-name="key"
                  v-bind:action-name="current_prototype.method"
                  v-on:valuePicked="
                    (data) => {
                      setPrototypeArgsMixins(key, data)
                    }
                  "
                >
                </driver-action-ui>
              </div>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col
              v-if="prototypeData.prototypes.length && !is_first_prototype"
            >
              <b-button block @click="setPreviousPrototype()">
                <b-icon-chevron-left></b-icon-chevron-left>
                {{ $t('ems.timeline.events.back') }}
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="!ui_edit_mode"
                :variant="!is_last_prototype ? 'primary' : 'success'"
                block
                @click="
                  !is_last_prototype ? setNextPrototype() : setLastPrototype()
                "
                :disabled="!prototype_setup_valid"
              >
                {{
                  !is_last_prototype
                    ? $t('ems.timeline.events.next')
                    : $t('ems.timeline.save')
                }}
              </b-button>
              <b-button
                v-if="ui_edit_mode"
                variant="success"
                block
                @click="updateEvent()"
                :disabled="!prototype_setup_valid"
              >
                {{ $t('ems.timeline.events.applyChanges') }}
              </b-button>
              <div v-if="current_prototype">
                <hr />
                <small
                  v-if="current_prototype"
                  class="text-secondary mt-2 text-center"
                  style="display: block"
                  >Uuid: {{ current_prototype.uuid }}</small
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </sidebar>
    <!-- Secondary navigation -->
    <navbar-secondary v-if="timelineData">
      <template v-slot:items>
        <b-nav-item>
          <b-dropdown
            :text="$t('ems.timeline.details.view')"
            :disabled="!userHasAccess([scopeMethods.WRITE], false)"
          >
            <b-dropdown-group
              :header="$t('ems.timeline.details.changeView').toUpperCase()"
            >
              <b-dropdown-item
                v-if="timelineData.published_revision && !is_published_view"
                @click="setRevisionView(published_rev)"
              >
                <span class="text-success">{{
                  $t('ems.timeline.published').toUpperCase()
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  !is_latest_view &&
                  timelineData.published_revision !== timelineData.revision
                "
                @click="setRevisionView(timelineData.revision)"
              >
                <span class="text-primary">{{
                  $t('ems.timeline.changed').toUpperCase()
                }}</span>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </b-nav-item>
        <b-nav-item v-if="!is_snapshot_view">
          <b-dropdown
            :disabled="!userHasAccess([scopeMethods.WRITE], false)"
            :text="
              !timelineData.published_revision
                ? $t('ems.common.status') + ': ' + $t('ems.timeline.draft')
                : is_published_view
                  ? $t('ems.common.status') +
                    ': ' +
                    $t('ems.timeline.published')
                  : $t('ems.common.status') + ': ' + $t('ems.timeline.changed')
            "
            :variant="
              !timelineData.published_revision
                ? 'warning'
                : is_published_view
                  ? 'success'
                  : 'primary'
            "
          >
            <b-dropdown-group
              :header="$t('ems.timeline.details.changeStatus').toUpperCase()"
            >
              <b-dropdown-item
                variant="warning"
                @click="draft()"
                v-if="timelineData.published_revision"
              >
                {{ $t('ems.timeline.draft') }}
              </b-dropdown-item>
              <b-dropdown-item
                variant="success"
                @click="openSnapshotDialog()"
                v-if="!is_published_view"
              >
                {{ $t('ems.timeline.published') }}
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-divider v-if="is_changed"></b-dropdown-divider>
            <b-dropdown-item
              variant="primary"
              @click="discardChanges()"
              v-if="is_changed"
            >
              {{ $t('ems.timeline.details.discard') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-nav-item>
        <b-nav-item v-if="is_snapshot_view">
          <b-button
            :disabled="!userHasAccess([scopeMethods.WRITE], false)"
            variant="warning"
            @click="$bvModal.show('restore-snapshot-dialog')"
          >
            <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
            {{ $t('ems.timeline.details.restore') }}
          </b-button>
        </b-nav-item>
        <b-nav-item>
          <b-dropdown
            right
            :text="$t('ems.timeline.details.snapshots')"
            variant="outline-dark"
            :disabled="
              !snapshots_data ||
              !userHasAccess([scopeMethods.WRITE, scopeMethods.DELETE], true)
            "
            @hidden="resetSnapshotData()"
          >
            <!-- if snapshots available -->
            <b-dropdown-group
              :header="
                $t('ems.timeline.details.availableSnapshots').toUpperCase()
              "
              class="text-right"
              v-if="snapshots_data"
            >
              <b-dropdown-form
                v-for="(snapshot, index) in snapshots_data"
                class="d-inline"
                v-bind:key="'rev' + index"
              >
                <div class="text-nowrap">
                  <b-icon-arrow-right-short
                    class="text-primary"
                    v-if="$route.params.rev === snapshot.revision"
                  >
                  </b-icon-arrow-right-short>
                  <span
                    @click="setRevisionView(snapshot.revision)"
                    :class="
                      $route.params.rev === snapshot.revision
                        ? 'text-primary'
                        : 'text-dark'
                    "
                  >
                    {{ snapshot.meta.name }}
                  </span>
                  <b-button
                    variant="link text-dark"
                    size="sm"
                    @click="onEditSnapshot(snapshot)"
                  >
                    <b-icon-pencil></b-icon-pencil>
                  </b-button>
                  <b-button
                    v-if="snapshot.revision !== published_rev"
                    :disabled="!userHasAccess([scopeMethods.DELETE], false)"
                    variant="link"
                    size="sm"
                    @click="onDeleteSnapshot(snapshot)"
                  >
                    <b-icon-trash class="text-danger"></b-icon-trash>
                  </b-button>
                </div>
              </b-dropdown-form>
              <b-dropdown-divider
                v-if="snapshotData.edit"
                class="my-2"
              ></b-dropdown-divider>
              <b-dropdown-form v-if="snapshotData.edit">
                <b-input
                  id="inline-form-input-username"
                  :placeholder="snapshotData.edit.meta.name"
                  v-model="snapshotData.edit.meta.name"
                >
                </b-input>
                <b-button
                  variant="btn btn-outline-danger"
                  size="sm"
                  @click="resetSnapshotData()"
                  class="m-2"
                >
                  {{ $t('ems.timeline.cancel') }}
                </b-button>
                <b-button
                  variant="btn btn-outline-primary"
                  size="sm"
                  @click="onUpdateSnapshot()"
                  class="my-2"
                >
                  {{ $t('ems.timeline.save') }}
                </b-button>
              </b-dropdown-form>
            </b-dropdown-group>
          </b-dropdown>
        </b-nav-item>
        <b-nav-item>
          <b-button
            :disabled="!userHasAccess([scopeMethods.WRITE], false)"
            @click="
              is_snapshot_view
                ? $bvModal.show('restore-snapshot-dialog')
                : $refs.trackSidebar.open()
            "
            variant="primary"
          >
            + {{ $t('ems.timeline.details.createTrack') }}
          </b-button>
        </b-nav-item>
        <b-nav-item>
          <b-dropdown
            variant="outline-dark"
            right
            :disabled="!userHasAccess([scopeMethods.WRITE], false)"
          >
            <template slot="button-content">
              <b-icon-gear-wide-connected></b-icon-gear-wide-connected>
            </template>
            <b-dropdown-group :header="$t('ems.timeline.templates')">
              <b-dropdown-item v-b-modal.create-template-dialog>
                <b-icon-layers></b-icon-layers>
                {{ $t('ems.timeline.details.saveTemplate') }}
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </b-nav-item>
      </template>
    </navbar-secondary>

    <!-- BEGIN: timeline -->
    <timeline
      class="mt-3"
      :ref="timeline_uuid"
      v-if="timelineData"
      v-bind:meta="timelineData"
      v-bind:tracks="tracks"
      v-bind:clients="clients"
      v-bind:drivers="drivers"
      v-bind:driver-aliases="driverAliases"
    >
    </timeline>

    <b-alert :show="!tracks.length" class="text-center mt-5 mx-5">
      <b-icon-info-circle></b-icon-info-circle>
      {{ $t('ems.timeline.details.noTracks') }}
    </b-alert>

    <b-modal
      id="create-snapshot-dialog"
      :title="$t('ems.timeline.details.createSnapshot')"
      v-model="dialogsEnabled.snapshotDialog"
      ok-variant="success"
      :ok-title="$t('ems.timeline.details.saveSnapshot')"
      :cancel-title="$t('ems.timeline.details.cancelSaveSnapshot')"
      centered
      hideHeaderClose
      v-on:ok="publish(true)"
      v-on:cancel="publish(false)"
    >
      <div class="text-center">
        <b-icon-cloud-upload class="h1"></b-icon-cloud-upload>
        <p class="mb-3">
          {{ $t('ems.timeline.details.snapshotHint') }}
        </p>
        <p class="text-left px-3">
          <label label-for="timeline-driver"
            >{{ $t('ems.timeline.details.name') }}:</label
          >
          <b-form-input
            v-model="snapshotData.name"
            :placeholder="snapshotData.name"
          ></b-form-input>
        </p>
      </div>
    </b-modal>

    <b-modal
      id="restore-snapshot-dialog"
      :title="$t('ems.timeline.details.restoreSnapshot')"
      ok-variant="success"
      :ok-title="$t('ems.timeline.ok')"
      :cancel-title="$t('ems.timeline.cancel')"
      centered
      hideHeaderClose
      v-on:ok="restoreSnapshot()"
    >
      <div class="text-center">
        <b-icon-cloud-download class="h1"></b-icon-cloud-download>
        <p>
          {{ $t('ems.timeline.details.restoreHint') }}
        </p>
      </div>
    </b-modal>

    <b-modal
      id="unassigned-events-dialog"
      title="Found unassigned events!"
      ok-variant="danger"
      ok-title="Remove all"
      centered
      hideHeaderClose
      size="xl"
      v-on:ok="removeBrokerEvents(parse_unassigned_event_data)"
    >
      <div class="text-center">
        <b-table
          hover
          head-variant="light"
          :items="unassigned_events_data"
        ></b-table>
      </div>
    </b-modal>

    <b-modal
      id="create-template-dialog"
      v-model="dialogsEnabled.templateDialog"
      :title="$t('ems.timeline.details.createTemplate')"
      ok-variant="success"
      :ok-title="$t('ems.timeline.save')"
      :cancel-title="$t('ems.timeline.cancel')"
      centered
      hideHeaderClose
      size="md"
      v-on:ok="createTemplate()"
    >
      <div>
        <b-form-group>
          <b-form-checkbox class="my-3" v-model="templateData.is_default">
            {{ $t('ems.timeline.details.setDefault') }}
          </b-form-checkbox>
          <b-form-input
            v-model="templateData.name"
            :placeholder="$t('ems.timeline.details.giveTemplateName')"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </b-modal>

    <b-modal
      id="helper-dialog"
      :title="$t('ems.timeline.did_you_know.header')"
      ok-only
      centered
      size="xl"
    >
      <div>
        <ul>
          <li class="mb-2">
            <b>{{ $t('ems.timeline.did_you_know.unlock_head') }}</b
            ><br />{{ $t('ems.timeline.did_you_know.unlock_txt') }}
          </li>
          <li class="mb-2">
            <b>{{ $t('ems.timeline.did_you_know.zoom_head') }}</b
            ><br />{{ $t('ems.timeline.did_you_know.zoom_txt') }}
          </li>
          <li class="mb-2">
            <b>{{ $t('ems.timeline.did_you_know.pan_head') }}</b
            ><br />{{ $t('ems.timeline.did_you_know.pan_txt') }}
          </li>
          <li class="mb-2">
            <b>{{ $t('ems.timeline.did_you_know.sync_head') }}</b
            ><br />{{ $t('ems.timeline.did_you_know.sync_txt') }}
          </li>
          <li class="mb-2">
            <b>{{ $t('ems.timeline.did_you_know.copy_head') }}</b
            ><br />{{ $t('ems.timeline.did_you_know.copy_txt') }}
          </li>
        </ul>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-form-checkbox
            class="float-left"
            name="checkbox-dismiss"
            v-model="dismissQuickTip"
          >
            {{ $t('ems.timeline.did_you_know.dismiss') }}
          </b-form-checkbox>
        </div>
      </template>
    </b-modal>

    <!-- END: content -->
  </div>
</template>

<script>
  import Vue from 'vue'
  import moment from 'moment-timezone'
  import { RRule, RRuleSet } from 'rrule'
  import EventPrototype from '@/js/eventprototype'
  import uuid4 from 'uuid4'
  import VueCookies from 'vue-cookies'

  export default {
    components: {
      NavbarSecondary: () => import('@/components/NavbarSecondary.vue'),
      Sidebar: () => import('@/components/Sidebar.vue'),
      Timeline: () => import('@/components/Timeline.vue')
    },
    data () {
      return {
        viewMounted: null,
        spaceBarPressed: false,
        altPressed: false,
        dismissQuickTip: false,
        driverData: null,
        clientError: null,
        clients: [],
        drivers: [],
        tracks: [],
        timelineData: null,
        unassignedEvents: [],
        min_event_duration: '00:00:00',
        templateData: {
          name: null,
          is_default: null
        },
        timeRegex: new RegExp('^((?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$)'),

        dialogsEnabled: {
          snapshotDialog: false,
          snapshotEdit: false,
          templateDialog: false
        },

        argsList: null, // for add event
        driverAliases: {},
        driverAliasIds: {},

        trackData: {
          clientUuid: null,
          name: null,
          group: null,
          enabled: null,
          uuid: null,
          shortcut_list: []
        },

        prototypeData: {
          ui_title: this.$t('ems.timeline.events.createEvent'),
          ui_desc: this.$t('ems.timeline.events.createEventHint'),
          mode: null,
          driverData: null,
          trackUuid: null,
          trackData: null,
          method: null,
          prototypes: []
        },

        rruleSetOptions: {
          rruleSet: null,
          count: null,
          interval: null,
          selected: null, // will be frequency def
          untilDate: null,
          untilTime: null,
          options: [
            {
              value: null,
              text: this.$t('ems.timeline.events.noRepeat')
            },
            {
              value: RRule.MINUTELY,
              text: this.$t('ems.timeline.events.everyMinute')
            },
            {
              value: RRule.HOURLY,
              text: this.$t('ems.timeline.events.everyHour')
            },
            {
              value: RRule.DAILY,
              text: this.$t('ems.timeline.events.everyDay')
            },
            {
              value: RRule.WEEKLY,
              text: this.$t('ems.timeline.events.everyWeek')
            },
            {
              value: RRule.MONTHLY,
              text: this.$t('ems.timeline.events.everyMonth')
            },
            {
              value: RRule.YEARLY,
              text: this.$t('ems.timeline.events.everyYear')
            }
          ],
          byweekday: [
            {
              value: RRule.MO,
              caption: this.$t('ems.timeline.events.mo'),
              state: false
            },
            {
              value: RRule.TU,
              caption: this.$t('ems.timeline.events.tu'),
              state: false
            },
            {
              value: RRule.WE,
              caption: this.$t('ems.timeline.events.we'),
              state: false
            },
            {
              value: RRule.TH,
              caption: this.$t('ems.timeline.events.th'),
              state: false
            },
            {
              value: RRule.FR,
              caption: this.$t('ems.timeline.events.fr'),
              state: false
            },
            {
              value: RRule.SA,
              caption: this.$t('ems.timeline.events.sa'),
              state: false
            },
            {
              value: RRule.SU,
              caption: this.$t('ems.timeline.events.su'),
              state: false
            }
          ],
          bymonth: [
            {
              value: 1,
              caption: this.$t('ems.timeline.events.jan'),
              state: false
            },
            {
              value: 2,
              caption: this.$t('ems.timeline.events.feb'),
              state: false
            },
            {
              value: 3,
              caption: this.$t('ems.timeline.events.mar'),
              state: false
            },
            {
              value: 4,
              caption: this.$t('ems.timeline.events.apr'),
              state: false
            },
            {
              value: 5,
              caption: this.$t('ems.timeline.events.may'),
              state: false
            },
            {
              value: 6,
              caption: this.$t('ems.timeline.events.jun'),
              state: false
            },
            {
              value: 7,
              caption: this.$t('ems.timeline.events.jul'),
              state: false
            },
            {
              value: 8,
              caption: this.$t('ems.timeline.events.aug'),
              state: false
            },
            {
              value: 9,
              caption: this.$t('ems.timeline.events.sep'),
              state: false
            },
            {
              value: 10,
              caption: this.$t('ems.timeline.events.oct'),
              state: false
            },
            {
              value: 11,
              caption: this.$t('ems.timeline.events.nov'),
              state: false
            },
            {
              value: 12,
              caption: this.$t('ems.timeline.events.dec'),
              state: false
            }
          ]
        },
        snapshotData: {
          created: null,
          name: null,
          cal_uuid: null,
          edit: null
        }
      }
    },
    computed: {
      timeline_uuid () {
        return this.timelineData ? this.timelineData['uuid'] : null
      },
      snapshots_data () {
        if (
          this.timelineData &&
          this.timelineData['snapshots_metadata'].length
        ) {
          return this.timelineData['snapshots_metadata']
        } else {
          return null
        }
      },
      published_rev () {
        return this.timelineData
          ? this.timelineData['published_revision']
          : null
      },
      is_published_view () {
        return this.published_rev
          ? this.published_rev === parseFloat(this.$route.params.rev)
          : null
      },

      is_latest_view () {
        return this.timelineData
          ? this.timelineData['revision'] === parseFloat(this.$route.params.rev)
          : null
      },

      is_changed () {
        let n = this.timelineData['revision']
        return (
          this.timelineData['revision'] > 1 && Number(n) === n && n % 1 !== 0
        )
      },

      is_snapshot_view () {
        if (this.timelineData) {
          let revs = [...this.timelineData['snapshots_available']]
          if (revs.includes(this.timelineData['published_revision'])) {
            revs.splice(
              revs.indexOf(this.timelineData['published_revision']),
              1
            )
          }
          return revs.includes(this.$route.params.rev) && !this.is_latest_view
        }
        return null
      },

      dialog_toggled () {
        return (
          Object.keys(this.dialogsEnabled).some(
            (k) => this.dialogsEnabled[k]
          ) ||
          (this.$refs.trackSidebar && this.$refs.trackSidebar.state.isOpen) ||
          (this.$refs.eventWizard && this.$refs.eventWizard.state.isOpen)
        )
      },

      track_edit_enabled () {
        return this.spaceBarPressed
      },

      alt_key_pressed () {
        return this.altPressed
      },

      drivers_list () {
        let drivers = []
        // clients in timeline
        let clientIds = this.tracks.map((t) => {
          return t.client_uuid
        })

        this.clients.forEach((c) => {
          if (!clientIds.includes(c.clientId)) {
            drivers.push({
              value: c.clientId,
              text: c.clientName + ' (' + this.getDriverAlias(c) + ')'
            })
          }
        })
        return drivers
      },

      methods_list () {
        let methodsList = this.getMethodsList(this.prototypeData.driverData)
        let methods = {
          selected: null,
          options: []
        }

        methodsList.forEach((m) => {
          if (!m.methodsList) {
            methods.options.push({
              value: [m],
              text: m.method
            })
          } else {
            let list = []
            m.methodsList.forEach((_m) => {
              list.push(_m)
            })
            methods.options.push({
              value: list,
              text: m.method
            })
          }
        })
        return methods
      },

      shortcut_list () {
        let client = this.getClient(this.trackData.clientUuid)
        if (!client) {
          return []
        }

        let driver = this.getDriverByClient(client)
        let methodsList = this.getMethodsList(client)
        let methods = []

        methodsList.forEach((m) => {
          if (!m.methodsList) {
            methods.push({
              value: m.method,
              text: m.method
            })
          } else {
            let hasParams = []
            m.methodsList.forEach((_m) => {
              if (driver.getEventParameterHandler(_m.method)) {
                hasParams.push(_m)
              }
            })
            methods.push({
              value: m.method,
              text: m.method
            })
          }
        })
        return methods
      },

      current_prototype () {
        return this.prototypeData.prototypes.find(
          (element) => !element.configured
        )
      },

      is_first_prototype () {
        return (
          this.prototypeData.prototypes.indexOf(this.current_prototype) === 0
        )
      },

      is_last_prototype () {
        let e =
          this.prototypeData.prototypes[
            this.prototypeData.prototypes.length - 1
          ]
        return e === this.current_prototype
      },

      get_prev_prototype () {
        let cidx = this.prototypeData.prototypes.indexOf(this.current_prototype)
        let lidx =
          this.prototypeData.prototypes[
            this.prototypeData.prototypes.length - 1
          ]
        return cidx !== lidx ? this.prototypeData.prototypes[cidx - 1] : null
      },

      get_next_prototype () {
        let cidx = this.prototypeData.prototypes.indexOf(this.current_prototype)
        let lidx =
          this.prototypeData.prototypes[
            this.prototypeData.prototypes.length - 1
          ]
        return cidx !== lidx ? this.prototypeData.prototypes[cidx + 1] : null
      },

      input_disabled () {
        return (
          this.prototypeData.prototypes.length > 1 && !this.is_first_prototype
        )
      },

      prototype_setup_valid () {
        return (
          this.prototypeData.prototypes.length &&
          this.current_prototype.isValid &&
          this.timeRegex.test(this.current_prototype.time) &&
          (!this.set_duration_enabled ||
            this.timeRegex.test(this.current_prototype.durationHHMMSS))
        )
      },

      ui_edit_mode () {
        return (
          this.prototypeData.prototypes.length &&
          this.prototypeData.mode === 'EDIT'
        )
      },

      track_data_set () {
        return Object.values({
          name: this.trackData.name,
          driver: this.trackData.clientUuid
        }).every((x) => x !== null)
      },
      unassigned_events_data () {
        let tableData = []
        this.unassignedEvents.forEach((e) => {
          tableData.push({
            date: moment(e.timestamp.seconds * 1000).format(
              'YYYY-MM-DD - HH:mm:ss'
            ),
            eventID: e.eventId,
            client: e.rpcRequest.clientId
          })
        })
        return tableData
      },
      parse_unassigned_event_data () {
        console.debug(this.unassignedEvents)
        let data = []
        this.unassignedEvents.forEach((e) => {
          data.push({
            broker_uid: e.eventId
          })
        })
        return data
      },
      track_name () {
        let val = null
        if (this.trackData.clientUuid) {
          this.clients.forEach((c) => {
            if (c.clientId === this.trackData.clientUuid) {
              val = c.clientName
            }
          })
        }
        return val
      },
      set_duration_enabled () {
        return (
          this.prototypeData.prototypes.length &&
          this.current_prototype.hasDurationArgs &&
          this.prototypeData.prototypes.length > 1
        )
      },
      scopeMethods () {
        return Vue.prototype.$keycloakmanager.scopeMethods
      },
      canUseEditKeys () {
        return this.userHasAccess([this.scopeMethods.WRITE], false)
      }
    },
    watch: {
      $route () {
        this.getTimelineData()
      },
      track_name () {
        this.trackData.name = this.track_name
      },
      dismissQuickTip () {
        VueCookies.set('dismissQuickTip', this.dismissQuickTip, '7d')
      }
    },
    methods: {
      userHasAccess (methods, isPermissive = true) {
        return Vue.prototype.$keycloakmanager.userHasAccess(
          Vue.prototype.$keycloakmanager.scopes.TIMELINES,
          methods,
          isPermissive
        )
      },
      // === on handlers =======================================================

      openSnapshotDialog () {
        this.snapshotData.name = this.$t('ems.timeline.events.snapshotFrom', {
          date: moment().format('YYYY-MM-DD HH:mm')
        })
        this.$bvModal.show('create-snapshot-dialog')
      },

      onCloseTrackSidebar () {
        this.trackData = {
          driver: null,
          clientUuid: null,
          name: null,
          group: null,
          enabled: null,
          uuid: null,
          shortcut_list: []
        }
      },

      resetPrototypeData () {
        this.prototypeData = {
          ui_title: this.$t('ems.timeline.events.createEvent'),
          ui_desc: this.$t('ems.timeline.events.createEventHint'),
          mode: null,
          driverData: null,
          trackUuid: null,
          trackData: null,
          method: null,
          prototypes: []
        }

        this.rruleSetOptions = {
          rruleSet: null,
          count: null,
          interval: null,
          selected: null, // will be frequency def
          untilDate: null,
          untilTime: null,
          options: [
            {
              value: null,
              text: this.$t('ems.timeline.events.noRepeat')
            },
            {
              value: RRule.MINUTELY,
              text: this.$t('ems.timeline.events.everyMinute')
            },
            {
              value: RRule.HOURLY,
              text: this.$t('ems.timeline.events.everyHour')
            },
            {
              value: RRule.DAILY,
              text: this.$t('ems.timeline.events.everyDay')
            },
            {
              value: RRule.WEEKLY,
              text: this.$t('ems.timeline.events.everyWeek')
            },
            {
              value: RRule.MONTHLY,
              text: this.$t('ems.timeline.events.everyMonth')
            },
            {
              value: RRule.YEARLY,
              text: this.$t('ems.timeline.events.everyYear')
            }
          ],
          byweekday: [
            {
              value: RRule.MO,
              caption: this.$t('ems.timeline.events.mo'),
              state: false
            },
            {
              value: RRule.TU,
              caption: this.$t('ems.timeline.events.tu'),
              state: false
            },
            {
              value: RRule.WE,
              caption: this.$t('ems.timeline.events.we'),
              state: false
            },
            {
              value: RRule.TH,
              caption: this.$t('ems.timeline.events.th'),
              state: false
            },
            {
              value: RRule.FR,
              caption: this.$t('ems.timeline.events.fr'),
              state: false
            },
            {
              value: RRule.SA,
              caption: this.$t('ems.timeline.events.sa'),
              state: false
            },
            {
              value: RRule.SU,
              caption: this.$t('ems.timeline.events.su'),
              state: false
            }
          ],
          bymonth: [
            {
              value: 1,
              caption: this.$t('ems.timeline.events.jan'),
              state: false
            },
            {
              value: 2,
              caption: this.$t('ems.timeline.events.feb'),
              state: false
            },
            {
              value: 3,
              caption: this.$t('ems.timeline.events.mar'),
              state: false
            },
            {
              value: 4,
              caption: this.$t('ems.timeline.events.apr'),
              state: false
            },
            {
              value: 5,
              caption: this.$t('ems.timeline.events.may'),
              state: false
            },
            {
              value: 6,
              caption: this.$t('ems.timeline.events.jun'),
              state: false
            },
            {
              value: 7,
              caption: this.$t('ems.timeline.events.jul'),
              state: false
            },
            {
              value: 8,
              caption: this.$t('ems.timeline.events.aug'),
              state: false
            },
            {
              value: 9,
              caption: this.$t('ems.timeline.events.sep'),
              state: false
            },
            {
              value: 10,
              caption: this.$t('ems.timeline.events.oct'),
              state: false
            },
            {
              value: 11,
              caption: this.$t('ems.timeline.events.nov'),
              state: false
            },
            {
              value: 12,
              caption: this.$t('ems.timeline.events.dec'),
              state: false
            }
          ]
        }
      },

      isSetInFirstPrototype (arg) {
        if (!this.is_first_prototype) {
          return this.prototypeData.prototypes[0].argsKeys.includes(arg)
        }
        return false
      },

      onSaveSettings () {
        if (!this.trackData.uuid) {
          // on track add
          this.addTrack(this.trackData).then(() => {
            this.getTracks()
          })
        } else {
          // on track edit
          this.getTrackEvents(this.trackData).then(() => {
            this.getTracks()
          })
        }
        this.updateEditInfo()
        this.$refs.trackSidebar.close()
      },

      onEditTrack (trackMeta) {
        this.trackData = {
          uuid: trackMeta.uuid,
          clientUuid: trackMeta.client_uuid,
          name: trackMeta.name,
          group: trackMeta.group,
          enabled: trackMeta.enabled,
          edit_mode: true,
          shortcut_list: trackMeta.shortcut_list
        }
        console.debug(trackMeta, this.trackData)
        this.$refs.trackSidebar.open()
      },

      onDeleteTrack (trackUuid) {
        this.deleteTrack(trackUuid).then(() => {
          this.getTracks()
        })
      },

      onAddEvent (trackMeta) {
        this.resetPrototypeData()
        this.prototypeData.mode = 'ADD'
        this.prototypeData.driverData = this.getClient(trackMeta.client_uuid)
        this.prototypeData.trackUuid = trackMeta.uuid
        this.prototypeData.trackData = trackMeta
        this.prototypeData.ui_desc =
          this.$t('ems.timeline.details.client') +
          ': ' +
          this.prototypeData.trackData.name
        this.$refs.eventWizard.open()
      },

      onAddEventQuickPick (method, trackMeta) {
        this.resetPrototypeData()
        this.prototypeData.mode = 'ADD'
        this.prototypeData.driverData = this.getClient(trackMeta.client_uuid)
        this.prototypeData.trackUuid = trackMeta.uuid
        this.prototypeData.trackData = trackMeta
        this.prototypeData.ui_desc =
          this.$t('ems.timeline.details.client') +
          ': ' +
          this.prototypeData.trackData.name
        this.$refs.eventWizard.open()
        const pickedMethod = this.methods_list.options.find(
          (o) => o.text === method
        )
        this.prototypeData.method = pickedMethod.value
        this.setPrototypeMethod()
      },

      onEditEvent (event) {
        // other important stuff
        this.prototypeData.trackUuid = event.trackUuid
        this.prototypeData.driverData = this.getClient(event.clientUuid)

        // get event refs
        let track = this.getDOMTrack(this.prototypeData.trackUuid)
        this.prototypeData.trackData = track.metaData
        let groupEvents = []
        if (event.group) {
          track.events.forEach((_e) => {
            if (event.group === _e.group) groupEvents.push(_e)
          })
        }

        // make prototype from event
        let p = new EventPrototype(event.method)
        p.setPrototypeDataFromEvent(event, groupEvents)
        this.prototypeData.prototypes.push(p)
        console.debug('prototype ready: ', p)

        // set rruleSet data
        if (event.rruleSet) {
          console.debug('onEditEvent: ', event.rruleSet)
          this.rruleSetOptions.rruleSet = event.rruleSet
          this.rruleSetOptions.count = event.rruleSet.options.count
          this.rruleSetOptions.interval = event.rruleSet.options.interval
          this.rruleSetOptions.selected = event.rruleSet.options.freq
          // set weekdays buttons active states
          if (event.rruleSet.options.byweekday) {
            this.rruleSetOptions.byweekday.forEach((v) => {
              if (event.rruleSet.options.byweekday.includes(v.value.weekday)) {
                v.state = true
              }
            })
          }
          if (event.rruleSet.options.bymonth) {
            this.rruleSetOptions.bymonth.forEach((v) => {
              if (event.rruleSet.options.bymonth.includes(v.value)) {
                v.state = true
              }
            })
          }

          console.debug(
            'rrulesSet ready',
            this.rruleSetOptions.rruleSet.toString()
          )
        }

        // set prototypeData ui
        this.prototypeData.mode = 'EDIT'
        this.prototypeData.ui_title =
          this.$t('ems.timeline.events.edit') + ': ' + p.method
        this.prototypeData.ui_desc =
          this.$t('ems.timeline.details.client') +
          ': ' +
          this.prototypeData.trackData.name
        this.$refs.eventWizard.open()
      },

      pushPrototypes () {
        console.debug('push prototypes')
        let track = this.getDOMTrack(this.prototypeData.trackUuid)
        let promises = []
        this.prototypeData.prototypes.forEach((e) => {
          let payload = {
            uuid: e.uuid,
            track_uuid: this.prototypeData.trackUuid,
            client_uuid: this.prototypeData.driverData.clientId,
            begin: e.begin,
            timezone: e.timezone,
            method: e.method,
            args: e.args,
            args_mixins:
              e.argsKeys.length && !e.nextRef | e.durationFixed
                ? e.argsMixins
                : {},
            rrules: e.rruleSet ? e.rruleSet.toString() : null,
            prev_ref: e.prevRef,
            next_ref: e.nextRef,
            group: e.group
          }
          promises.push(this.createEvent(payload))
        })

        Promise.all(promises).then(() => {
          this.$refs.eventWizard.close()
          track.updateTrack()
        })
      },

      setPreviousPrototype () {
        this.get_prev_prototype.configured = false
        this.updateEventUI()
      },

      setArgsFromFirstPrototype () {
        if (!this.is_first_prototype) {
          for (let arg in this.prototypeData.prototypes[0].args) {
            if (this.current_prototype.argsKeys.includes(arg)) {
              this.$set(
                this.current_prototype.args,
                arg,
                this.prototypeData.prototypes[0].args[arg]
              )
              this.current_prototype.args[arg] =
                this.prototypeData.prototypes[0].args[arg]
            }
          }
        }
      },

      setNextPrototype () {
        // set next and prev values
        this.current_prototype.nextRef = this.get_next_prototype.uuid
        this.get_next_prototype.prevRef = this.current_prototype.uuid

        // add rruleSet
        if (this.rruleSetOptions.rruleSet) {
          this.current_prototype.rruleSet =
            this.rruleSetOptions.rruleSet.toString()
        }
        // update begin of next event and prev event begin
        let durSec = this.current_prototype.duration
        this.get_next_prototype.updateDateTime(
          moment(this.current_prototype.begin).add(durSec, 'seconds')
        )

        this.current_prototype.configured = true
        this.setArgsFromFirstPrototype()
        this.updateEventUI()
      },

      setLastPrototype () {
        // add rruleSet
        if (this.rruleSetOptions.rruleSet) {
          this.current_prototype.rruleSet =
            this.rruleSetOptions.rruleSet.toString()
        }
        this.pushPrototypes()
      },

      updateEvent () {
        let prototype = this.prototypeData.prototypes[0]
        let track = this.getDOMTrack(this.prototypeData.trackUuid)
        let updateList = prototype.getUpdateList()

        // Apply same args to group
        if (prototype.inheritGroupEvents.length) {
          prototype.inheritGroupEvents.forEach((e) => {
            for (let arg in prototype.args) {
              if (arg in e.args) {
                e.args[arg] = prototype.args[arg]
              }
            }
            if (!updateList.includes(e)) updateList.push(e)
          })
        }

        // add rulesset
        if (this.rruleSetOptions.rruleSet) {
          if (prototype.inheritGroupEvents.length) {
            prototype.inheritGroupEvents.forEach((e) => {
              e.rruleSet = this.rruleSetOptions.rruleSet
              e.updateRRuleSet()
              if (!updateList.includes(e)) updateList.push(e)
            })
          } else {
            prototype.inheritPrototype.rruleSet = this.rruleSetOptions.rruleSet
            prototype.inheritPrototype.updateRRuleSet()
          }
        } else {
          console.warn('rrulest update ignored')
        }
        // do the update
        updateList.forEach((e) => {
          track.updateEvent(e).then(() => {
            track.updateTrack()
          })
        })
        this.$refs.eventWizard.close()
      },

      setPrototypeMethod () {
        console.debug('method: ', this.prototypeData.method)
        // reset events
        this.prototypeData.prototypes = []
        this.prototypeData.method.forEach((m) => {
          let p = new EventPrototype(m.method, this.timelineData['timezone'])
          this.prototypeData.prototypes.push(p)
          this.setPrototypeArgsKeys(p)
          console.debug('prototype created:', p)
        })
        // add group to meta
        if (this.prototypeData.prototypes.length > 1) {
          let group = uuid4()
          this.prototypeData.prototypes.forEach((p, i) => {
            p.group = group
            if (i < this.prototypeData.prototypes.length - 1) {
              this.$set(
                p.argsMixins,
                'duration',
                this.current_prototype.min_duration
              )
              this.$set(p.argsMixins, 'duration_fixed', false)
            }
          })
        }
        // clear argsKeys for last_event if now driver args set
        // will hide the duration input field
        let last =
          this.prototypeData.prototypes[
            this.prototypeData.prototypes.length - 1
          ]
        if (!last.argsKeys.length) {
          last.argsMixins = {}
        }

        this.updateEventUI()
      },

      updateEventUI () {
        // update ui show steps
        let idx = this.prototypeData.prototypes.indexOf(this.current_prototype)
        this.prototypeData.ui_title =
          this.$t('ems.timeline.events.step') +
          (idx + 1) +
          '/' +
          this.prototypeData.prototypes.length
      },

      setPrototypeArgsKeys (e) {
        e.argsKeys = []
        e.args = {}
        let driver = this.getDriverByClient(this.prototypeData.driverData)
        let args = driver.getEventParameterHandler(e.method)

        if (args) {
          for (let pHandler of args) {
            e.argsKeys.push(pHandler.name)
          }
        }
        console.debug('prototype argsKeys set: ', e.argsKeys)
      },

      setPrototypeArgsMixins (paramName, data) {
        // if (!this.set_duration_enabled) {
        //   console.debug('prototype argsMixins ignored: ', this.current_prototype.argsMixins)
        //   return
        // }
        // console.debug('klappt nicht: ', paramName, data)
        this.$set(this.current_prototype.args, paramName, data.value)
        if ('duration' in data) {
          if (!this.set_duration_enabled) {
            this.current_prototype.argsMixins = {}
          } else if (data.duration !== null) {
            this.$set(
              this.current_prototype.argsMixins,
              'duration',
              parseFloat(data.duration)
            )
            this.$set(this.current_prototype.argsMixins, 'duration_fixed', true)
          } else {
            this.$set(
              this.current_prototype.argsMixins,
              'duration',
              this.current_prototype.min_duration
            )
            this.$set(
              this.current_prototype.argsMixins,
              'duration_fixed',
              false
            )
          }
        }
        console.debug(
          'prototype argsMixins set: ',
          this.current_prototype.argsMixins
        )
      },

      setPrototypeRuleSet () {
        if (!this.rruleSetOptions.selected) {
          this.rruleSetOptions.rruleSet = null
        } else {
          this.rruleSetOptions.rruleSet = new RRuleSet()
          // basic rules
          let rule = {
            freq: this.rruleSetOptions.selected,
            count:
              this.rruleSetOptions.count < 1
                ? null
                : this.rruleSetOptions.count,
            interval: this.rruleSetOptions.interval
          }
          // parse byweekdays
          let byWeekday = this.rruleSetOptions.byweekday.filter(
            (item) => item.state
          )

          if (byWeekday.length) {
            rule.byweekday = byWeekday.map((d) => d.value)
          }
          // parse byweekdays
          let byMonth = this.rruleSetOptions.bymonth.filter(
            (item) => item.state
          )

          if (byMonth.length) {
            rule.bymonth = byMonth.map((d) => d.value)
          }
          // parse until
          if (
            this.rruleSetOptions.untilDate &&
            !this.rruleSetOptions.untilTime
          ) {
            this.rruleSetOptions.untilTime = '12:00:00'
          }
          if (
            !this.rruleSetOptions.untilDate &&
            this.rruleSetOptions.untilTime
          ) {
            this.rruleSetOptions.untilTime = null
          }
          if (
            this.rruleSetOptions.untilDate &&
            this.rruleSetOptions.untilTime
          ) {
            let day = moment(this.rruleSetOptions.untilDate).format(
              'YYYY-MM-DD'
            )
            rule.until = moment(day + 'T' + this.rruleSetOptions.untilTime)
          } else {
            rule.until = null
          }

          // create ruleset instance
          let rruleSet = this.rruleSetOptions.rruleSet
          rruleSet.rrule(new RRule(rule))
        }
        console.debug(
          'rrule string: ',
          this.rruleSetOptions.rruleSet.toString()
        )
      },

      // === scope utils =======================================================

      makeToast (msg, variant = null) {
        this.$bvToast.toast([msg], {
          title: 'TimelineAPI response',
          solid: true,
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-right',
          variant: variant
        })
      },

      getClients () {
        this.drivers = []
        this.clients = []
        this.driverAliases = {}
        this.driverAliasIds = {}
        this.$driverManager.updateBrokerData().then(async () => {
          this.drivers = this.$driverManager.drivers
          this.clients = this.$driverManager.clients
          for (let d of this.clients) {
            let driver = this.getDriver(d.driverId)
            let alias = await this.$driverManager.computeDriverAlias(d, driver)
            if (alias) {
              this.driverAliases[d.clientId] = alias
            }
            alias = this.getDriverAlias(d)
            this.driverAliasIds[d.clientId] = alias + d.driverId
          }
        })
      },

      getDriverAlias (client) {
        if (client.clientId in this.driverAliases) {
          return this.driverAliases[client.clientId]
        }
        return this.getDriver(client.driverId).name
      },

      getDriverAliasId (client) {
        return this.driverAliasIds[client.clientId]
      },

      listEvents (nextToken = null, events = null) {
        return new Promise((resolve) => {
          this.$driverManager
            .listEvents(nextToken)
            .then((data) => {
              if (events) {
                for (let d of data.eventsList) {
                  events.push(d)
                }
              } else {
                events = data.eventsList
              }
              if (data.nextPageToken) {
                resolve(this.listEvents(data.nextPageToken, events))
              } else {
                resolve(events)
              }
            })
            .catch((err) => {
              console.debug(err)
            })
        })
      },

      parseResponse (res) {
        if (res.msg['revision_changed']) {
          this.$router
            .push({
              name: 'timelineDetails',
              params: {
                cal_uuid: this.$route.params.cal_uuid,
                rev: res.msg['revision']
              }
            })
            .catch(() => {})
          this.getTracks().then((res) => {
            this.tracks.forEach((t) => {
              let track = this.getDOMTrack(t.uuid)
              track.updateTrack()
            })
          })
        }
      },

      setRevisionView (rev) {
        this.$router
          .push({
            name: 'timelineDetails',
            params: {
              cal_uuid: this.timeline_uuid,
              rev: rev
            }
          })
          .catch(() => {})
        this.getTracks().then((res) => {
          this.tracks.forEach((t) => {
            let track = this.getDOMTrack(t.uuid)
            track.updateTrack()
          })
        })
      },

      getDOMTrack (uuid) {
        // [0] is strictly needed to access $refs
        return this.$refs[this.timeline_uuid].$refs[uuid]
          ? this.$refs[this.timeline_uuid].$refs[uuid][0]
          : null
      },

      getDriver (driverId) {
        return this.drivers.find((d) => {
          return d.driverId === driverId
        })
      },

      getDriverByClient (client) {
        let aliasId = this.getDriverAliasId(client)
        return this.drivers.find(
          (d) => aliasId.includes(d.driverId) && aliasId.includes(d.alias)
        )
      },

      getClient (clientId) {
        return this.clients.find((c) => {
          return c.clientId === clientId
        })
      },

      getEventValues (client, prototypeData) {
        let driver = this.getDriverByClient(client)
        let list = driver.getEventValues(prototypeData.method)
        return list
      },

      getMethodsList (client) {
        let signatureList = []

        let driver = this.getDriverByClient(client)
        // get default methods
        driver.signaturesList.forEach((l) => {
          signatureList.push(l)
        })
        if (!driver.getConnectedEvents()) {
          return signatureList
        }
        // get custom methods with multiple events involved
        let customMethods = driver.getConnectedEvents()
        customMethods.forEach((m) => {
          signatureList.push({
            method: m.name,
            methodsList: this.parseMethodsList(m, driver)
          })
        })
        return signatureList
      },

      parseMethodsList (methodsList, driver) {
        let parsed = []
        methodsList.events.forEach((m) => {
          let method = driver.signaturesList.find((_m) => {
            return _m.method === m
          })
          parsed.push(method)
        })
        return parsed
      },

      publish (makeSnapshot) {
        this.releaseTimeline().then(() => {
          this.publishTimeline(makeSnapshot).then((res) => {
            this.timelineData = res.msg.result
            this.resetSnapshotData()
            this.cleanTimeline()

            this.getBrokerData().then((res) => {
              let promises = []
              let res_ = {
                del: [],
                add: []
              }
              if (res.msg.result['del'].length) {
                promises.push(
                  this.removeBrokerEvents(res.msg.result['del']).then(
                    (bRes) => {
                      res_['del'] = bRes
                    }
                  )
                )
              }

              if (res.msg.result['add'].length) {
                promises.push(
                  this.addBrokerEvents(res.msg.result['add']).then((bRes) => {
                    res_['add'] = bRes
                  })
                )
              }
              Promise.all(promises).then(() => {
                this.sendBrokerResponse(res_).then(() => {
                  this.tracks.forEach((t) => {
                    this.getDOMTrack(t.uuid).updateTrack()
                  })
                })
              })
            })
          })
        })
      },

      draft () {
        this.draftTimeline().then((res) => {
          this.timelineData = res.msg.result
          this.getBrokerData().then((res) => {
            let promises = []
            let res_ = {
              del: [],
              add: []
            }
            if (res.msg.result['del'].length) {
              promises.push(
                this.removeBrokerEvents(res.msg.result['del']).then((bRes) => {
                  res_['del'] = bRes
                })
              )
            }

            Promise.all(promises).then(() => {
              this.sendBrokerResponse(res_).then(() => {
                this.tracks.forEach((t) => {
                  this.getDOMTrack(t.uuid).updateTrack()
                })
              })
            })
          })

          this.tracks.forEach((t) => {
            this.getDOMTrack(t.uuid).updateTrack()
          })
        })
      },

      discardChanges () {
        this.discardTimeline().then(() => {
          this.cleanTimeline()
        })
      },

      onEditSnapshot (data) {
        console.debug(data)
        this.snapshotData.edit = JSON.parse(JSON.stringify(data))
        this.dialogsEnabled.snapshotEdit = true
      },

      onUpdateSnapshot () {
        this.updateSnapshot(this.snapshotData.edit).then((res) => {
          this.timelineData = res.msg.result
          this.resetSnapshotData()
        })
      },

      onDeleteSnapshot (data) {
        const h = this.$createElement
        const messageVNode = h('div', { class: ['text-center'] }, [
          h('p', [
            ' ' + this.$t('ems.timeline.details.snapshotDelete') + ' ',
            h('br'),
            h('br'),
            h('strong', data.meta.name)
          ])
        ])

        this.$bvModal
          .msgBoxConfirm(messageVNode, {
            title: this.$t('ems.timeline.details.sure'),
            okVariant: 'danger',
            okTitle: this.$t('ems.timeline.ok'),
            cancelTitle: this.$t('ems.timeline.cancel'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then((v) => {
            console.debug(v)
            if (v) {
              this.deleteSnapshot(data.revision).then((res) => {
                this.timelineData = res.msg.result
                this.cleanTimeline()
              })
            }
          })
      },

      resetSnapshotData () {
        this.dialogsEnabled.snapshotEdit = false
        this.snapshotData = {
          created: null,
          name: null,
          cal_uuid: null,
          edit: null
        }
      },

      // === API calls =========================================================

      getTimelineData () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/' +
              this.$route.params.cal_uuid,
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              this.timelineData = response.msg.result
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' getTimelineData', 'danger')
              reject(error)
            })
        })
      },

      getTracks () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/tracks/' +
              this.$route.params.cal_uuid +
              '/rev/' +
              this.$route.params.rev,
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              this.tracks = response.msg.result
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in getTracks', 'danger')
              reject(error)
            })
        })
      },

      getTrackEvents (data) {
        console.debug('getTrackEvents:', data)
        let body = {
          uuid: data.uuid,
          timeline_uuid: this.$route.params.cal_uuid,
          client_uuid: data.clientUuid,
          group: data.group,
          enabled: data.enabled,
          name: data.name,
          shortcut_list: data.shortcut_list
        }

        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/tracks/update', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Events fetched')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              this.makeToast(error + ' in getTrackEvents', 'danger')
              reject(error)
            })
        })
      },

      addTrack (data) {
        let body = {
          uuid: uuid4(),
          timeline_uuid: this.$route.params.cal_uuid,
          client_uuid: data.clientUuid,
          enabled: true,
          group: data.group,
          name: data.name,
          shortcut_list: data.shortcut_list
        }

        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/tracks/create', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Track added')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in addTrack', 'danger')
              reject(error)
            })
        })
      },

      deleteTrack (trackUuid) {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/tracks/delete/' + trackUuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader()
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Track deleted')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' deleteTrack', 'danger')
              reject(error)
            })
        })
      },

      createEvent (data) {
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/events/create', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(data)
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.parseResponse(response)
              // this.makeToast(response.msg.status + ' Event created')
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in createEvent', 'danger')
              reject(error)
            })
        })
      },

      restoreSnapshot () {
        let body = {
          uuid: this.timeline_uuid,
          revision: this.$route.params.rev
        }
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/snapshots/restore', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Snapshot restored')
              this.parseResponse(response)
              this.updateEditInfo()
              console.debug(response)
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in restoreSnapshot', 'danger')
              reject(error)
            })
        })
      },

      updateSnapshot (data) {
        let body = {
          revision: data.revision,
          cal_uuid: this.timeline_uuid,
          snapshot_meta: data.meta
        }
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/snapshots/update', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Snapshot updated')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in deleteSnapshot', 'danger')
              reject(error)
            })
        })
      },

      deleteSnapshot (revision) {
        let body = {
          uuid: this.timeline_uuid,
          revision: revision
        }
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/snapshots/delete', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Snapshot deleted')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in deleteSnapshot', 'danger')
              reject(error)
            })
        })
      },

      releaseTimeline () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/release/' +
              this.timeline_uuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader()
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Timeline released')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      getBrokerData () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/broker-data/timeline/' +
              this.timeline_uuid,
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              console.debug('cap_get_broker_data:', response)
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' in getBrokerData', 'danger')
              reject(error)
            })
        })
      },

      sendBrokerResponse (body) {
        console.debug('capi_set_broker_data: ', body)
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/broker-data/update', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Broker data updated')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      publishTimeline (makeSnapshot) {
        let payload = {
          create_snapshot: makeSnapshot,
          snapshot_meta: null
        }
        if (makeSnapshot) {
          this.snapshotData.cal_uuid = this.cal_uuid
          this.snapshotData.created = moment()
          payload.snapshot_meta = this.snapshotData
        }
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/publish/' +
              this.timeline_uuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader(),
              body: JSON.stringify(payload)
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + '  Timeline published')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      draftTimeline () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/draft/' +
              this.timeline_uuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader()
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Timeline drafted')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      discardTimeline () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/discard-changes/' +
              this.timeline_uuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader()
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Changes discarded')
              this.parseResponse(response)
              this.updateEditInfo()
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      cleanTimeline () {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB +
              '/timelines/clean/' +
              this.timeline_uuid,
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader()
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Timeline cleaned up')
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      updateEditInfo () {
        this.$keycloakmanager.loadUserInfo().then((res) => {
          let body = {
            uuid: this.timeline_uuid,
            updated: moment(),
            author: res.preferred_username
          }
          return new Promise((resolve) => {
            fetch(Vue.prototype.$TIMELINE_MONGODB + '/timelines/edit-info', {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader(),
              body: JSON.stringify(body)
            })
              .then((resp) => resp.json())
              .then((response) => {
                resolve(response)
              })
          })
        })
      },

      // === broker calls ======================================================

      removeBrokerEvents (res) {
        return new Promise((resolve) => {
          let promises = []
          res.forEach((e) => {
            promises.push(
              this.$driverManager.deleteEvent(e.broker_uid).then(
                () => {
                  console.debug('broker: ' + e.broker_uid + ' removed')
                  e.broker_uid = null
                },
                () => {
                  console.debug('broker: ' + e.broker_uid + ' not found!')
                  e.broker_uid = null
                }
              )
            )
          })
          Promise.allSettled(promises).then(() => {
            resolve(res)
          })
        })
      },

      addBrokerEvents (res) {
        return new Promise((resolve) => {
          let promises = []
          res.forEach((e) => {
            // TODO: Will crash needs adaption!
            let client = this.getClient(e.client_uuid)
            let driver = this.getDriverByClient(client)
            promises.push(
              this.$driverManager
                .createEvent(
                  moment(e.begin).unix(),
                  e.method,
                  client,
                  driver,
                  e.args,
                  e.timezone,
                  e.rrules
                )
                .then((brokerRes) => {
                  if (brokerRes.eventId !== undefined) {
                    e.broker_uid = brokerRes.eventId
                    console.debug('broker: ' + e.broker_uid + ' added')
                  }
                })
            )
          })
          Promise.all(promises).then(() => {
            resolve(res)
          })
        })
      },

      validateBrokerEvents (eventsList) {
        return new Promise((resolve, reject) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/broker-data/events/validate',
            {
              method: 'POST',
              headers: this.$keycloakmanager.getTokenHeader(),
              body: JSON.stringify({ 'broker-data': eventsList })
            }
          )
            .then((resp) => resp.json())
            .then((response) => {
              // this.makeToast(response.msg.status + ' Broker data validated')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

      createTemplate () {
        let body = {
          name: this.templateData.name,
          is_default: this.templateData.is_default,
          uuid: uuid4(),
          created: moment(),
          tracks: []
        }

        this.tracks.forEach((t) => {
          body.tracks.push({
            client_uuid: t.client_uuid,
            name: t.name,
            shortcut_list: t.shortcut_list,
            enabled: t.enabled,
            group: t.group
          })
        })
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/templates/create', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(body)
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.templateData = {
                name: null,
                is_default: null
              }
              // this.makeToast(response.msg.status + ' Template created')
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      onKeyDown (e) {
        // ignore if any dialog is active
        if (this.dialog_toggled || !this.viewMounted) return
        // prevent scroll down
        if (e.code === 'Space') e.preventDefault()
        // copy events
        if (e.code === 'AltLeft' && this.canUseEditKeys) {
          this.altPressed = true
        }
      },
      onKeyUp (e) {
        // ignore if any dialog is active
        if (this.dialog_toggled || !this.viewMounted) return

        if (e.code === 'Space' && this.canUseEditKeys) {
          this.spaceBarPressed = !this.spaceBarPressed
        } else if (e.code === 'KeyS') {
          this.tracks.forEach((t) => {
            let track = this.getDOMTrack(t.uuid)
            if (track.hovered) this.$root.$emit('sync-tracks', track.zoomScale)
          })
        } else if (e.code === 'AltLeft' && this.canUseEditKeys) {
          e.preventDefault()
          this.altPressed = false
        }
      }
    },
    mounted () {
      moment.tz.setDefault('GMT') // always use GMT
      // setup
      this.getClients()
      this.getTimelineData().then(() => {
        this.getTracks()
        this.listEvents().then((events) => {
          this.validateBrokerEvents(events).then((res) => {
            this.unassignedEvents = res['msg']['result']
            if (this.unassignedEvents.length) {
              this.$bvModal.show('unassigned-events-dialog')
            }
          })
        })
      })

      this.$root.$on('enableTrack', this.getTrackEvents)
      this.$root.$on('editEvent', this.onEditEvent)

      this.$root.$on('addEvent', (d) => {
        this.is_snapshot_view
          ? this.$bvModal.show('restore-snapshot-dialog')
          : this.onAddEvent(d)
      })
      this.$root.$on('copyEvent', (d) => {
        if (this.is_snapshot_view) {
          this.$bvModal.show('restore-snapshot-dialog')
        } else {
          console.debug('push single event: ', d)
          let track = this.getDOMTrack(d.track_uuid)
          this.createEvent(d).then(() => {
            track.updateTrack()
          })
        }
      })
      this.$root.$on('copyGroup', (g) => {
        if (this.is_snapshot_view) {
          this.$bvModal.show('restore-snapshot-dialog')
        } else {
          let track = this.getDOMTrack(g[0].track_uuid)
          let promises = []
          g.forEach((d) => {
            console.debug('push group event: ', d)
            promises.push(this.createEvent(d))
          })
          Promise.all(promises).then(() => {
            track.updateTrack()
          })
        }
      })
      this.$root.$on('addEvent-shortcut', (method, medtadata) => {
        this.is_snapshot_view
          ? this.$bvModal.show('restore-snapshot-dialog')
          : this.onAddEventQuickPick(method, medtadata)
      })
      this.$root.$on('editTrack', (d) => {
        this.is_snapshot_view
          ? this.$bvModal.show('restore-snapshot-dialog')
          : this.onEditTrack(d)
      })

      this.$root.$on('disableTrack', (d) => {
        this.is_snapshot_view
          ? this.$bvModal.show('restore-snapshot-dialog')
          : this.getTrackEvents(d)
      })

      this.$root.$on('deleteTrack', (d) => {
        this.is_snapshot_view
          ? this.$bvModal.show('restore-snapshot-dialog')
          : this.onDeleteTrack(d)
      })

      this.$root.$on('restore-snapshot-dialog', () => {
        this.$bvModal.show('restore-snapshot-dialog')
      })

      window.addEventListener('keydown', this.onKeyDown)
      window.addEventListener('keyup', this.onKeyUp)
      this.viewMounted = true

      // did you know - modal handling
      this.dismissQuickTip = VueCookies.get('dismissQuickTip')
        ? VueCookies.get('dismissQuickTip')
        : 'false'
      if (this.dismissQuickTip === 'false') this.$bvModal.show('helper-dialog')
    },
    beforeDestroy () {
      this.$root.$off('addEvent')
      this.$root.$off('copyEvent')
      this.$root.$off('copyGroup')
      this.$root.$off('addEvent-shortcut')
      this.$root.$off('editEvent')
      this.$root.$off('editTrack')
      this.$root.$off('enableTrack')
      this.$root.$off('disableTrack')
      this.$root.$off('deleteTrack')
      this.$root.$off('restore-snapshot-dialog')

      window.removeEventListener('keyup', this.onKeyUp)
      window.removeEventListener('keydown', this.onKeyDown)
      this.viewMounted = false
    }
  }
</script>

<style lang="scss" scoped></style>
